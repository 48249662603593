const styles = (theme) => ({
  container: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000,
    width: "fit-content",
    // height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  containerMobile: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    maxWidth: "lg",
    padding: theme.spacing(0),
    marginTop: theme.spacing(0),
  },
});
export default styles;
