const styles = (theme) => ({
  "@global": {
    ul: {
      fontSize: "1rem",
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.2rem",
      },
      // listStyle: "circle",
      paddingLeft: theme.spacing(8),
    },
    li: {
      fontSize: "1rem",
    },
    p: {
      color: theme.palette.text.primary,
      fontSize: "0.9rem",
      fontWeight: "normal",

      // [theme.breakpoints.up("md")]: {
      //   fontSize: "1rem",
      // },
      // fontSize: "0.9rem",
      // paddingTop: theme.spacing(2),
      // paddingLeft: theme.spacing(0),
      // paddingRight: theme.spacing(0),
      // [theme.breakpoints.up("sm")]: {
      //   fontSize: "1.2rem",
      //   paddingLeft: theme.spacing(4),
      //   paddingRight: theme.spacing(4),
      // },
    },
    strong: {
      color: theme.palette.primary.main,
    },
    img: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      // width: "100%",
      // height: "auto",
      // maxWidth: "650px",

    },
    h2: {
      color: theme.palette.text.main,
      textAlign: "center",
    },
    h3: {
      marginTop: theme.spacing(4),
      // paddingBottom: theme.spacing(0),
      color: theme.palette.secondary.main,
      textAlign: "center",
    },
    h4: {
      fontSize: "1.5rem",
      marginTop: theme.spacing(4),
      fontWeight: 400
    },
    a: {
      textDecoration: "none",
      color: theme.palette ? theme.palette.secondary.main : "#F26363",
      // fontWeight: "bold",
    },
    p: {
      fontSize: "1rem",
      fontWeight: "normal",
    },
    div: {
      textAlign: "justify",
    },
    contentWidth: {
      width: "80%",
    },
    pre: {
      [theme.breakpoints.down("sm")]: {
        backgroundColor: theme.palette.grey[300],
        margin: theme.spacing(0),
        borderRadius: "25px",
        overflowX: "scroll",
      },
      backgroundColor: theme.palette.grey[300],
      margin: theme.spacing(4),
      padding: theme.spacing(2),
      borderRadius: "25px",
    }
  },

});
export default styles;
