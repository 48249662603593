import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PreviewCompatibleImage from "../PreviewCompatibleImage";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { alpha } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Link } from "gatsby";
const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    margin: theme.spacing(1),
    flex: 1,
    height: "100%",
    maxWidth: 350,

    // [theme.breakpoints.down("sm")]: {
    //   maxWidth: 500,
    // },
    [theme.breakpoints.down("xs")]: {
      maxWidth: 450,
      height: 300,
    },



  },
  overlayPrimary: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '100%',
    width: "100%",
    color: theme.palette.common.white,
    // background: `radial-gradient(circle at center , ${alpha(theme.palette.primary.main, 0.1)},${alpha(theme.palette.primary.main, 0.2)
    //   } 70%, ${alpha(theme.palette.primary.main, 0.7)} 100%)`,
    background: `radial-gradient(circle at center , ${alpha(theme.palette.common.white, 0)},${alpha(theme.palette.common.white, 0.2)
      } 80%, ${alpha(theme.palette.common.white, 0.5)} 10%)`,
    // transition: ".5s",
    // background: `linear-gradient(180deg , ${alpha(theme.palette.primary.main, 1)},${alpha(theme.palette.primary.main, 0.4)
    //   } 40%, ${alpha(theme.palette.primary.main, 0.9)}65%)`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {

      // background: `radial-gradient(circle at 50% 30%, ${alpha(theme.palette.common.white, 0)},${alpha(theme.palette.common.white, 0.6)
      //   } 50%, ${alpha(theme.palette.common.white, 0.7)} 100%)`,
      background: `linear-gradient(180deg , ${alpha(theme.palette.common.white, 0.2)},${alpha(theme.palette.common.white, 0.1)
        } 40%, ${alpha(theme.palette.common.white, 0.9)}65%)`,
      color: theme.palette.text.primary,
      '& $text2': {
        paddingTop: 0,
        marginBottom: 0,
        fontSize: ".9rem",
        '& strong': {
          color: theme.palette.primary.main,
        }
      },
      '& $text': {

        fontSize: "1rem",

      },
    }


  },
  overlaySecondary: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '100%',
    width: "100%",
    color: theme.palette.common.white,
    // 
    background: `radial-gradient(circle at center , ${alpha(theme.palette.common.black, 0.2)},${alpha(theme.palette.common.black, 0.3)
      } 90%, ${alpha(theme.palette.common.black, 0.9)} 100%)`,

    // background: `linear-gradient(180deg , ${alpha(theme.palette.grey[900], 0.8)},${alpha(theme.palette.grey[900], 0.2)
    //   } 25%, ${alpha(theme.palette.grey[900], 0.8)}65%)`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",

  },
  title: {

    fontFamily: "Lovelo",
    paddingTop: theme.spacing(3),
    flexGrow: 1,

  },
  text: {
    paddingTop: theme.spacing(3),
    // transition: ".5s",
    opacity: 0,
    [theme.breakpoints.down("sm")]: {
      opacity: 1,
    },
  },
  text2: {
    paddingTop: theme.spacing(3),


    // transition: ".5s",

  },
  link: {
    height: "100%",
    width: "100%",

    '&:hover': {

      '& $text': {
        opacity: 1,

      },
      '& $title': {
        color: theme.palette.text.main,

      },
      '& $text2': {
        '& strong': {
          color: theme.palette.primary.main,
        }
      },

      '& $overlayPrimary': {
        // background: `linear-gradient(180deg , ${alpha(theme.palette.common.white, 0.6)},${alpha(theme.palette.common.white, 0.4)
        //   } 30%, ${alpha(theme.palette.common.white, 0.7)}45%)`,

        background: `radial-gradient(circle at 50% 30%, ${alpha(theme.palette.common.white, 0)},${alpha(theme.palette.common.white, 0.8)
          } 50%, ${alpha(theme.palette.common.white, 0.9)} 100%)`,
        color: theme.palette.text.primary,

      },
      // '& $imageMarked': {
      //   opacity: 0,
      // },

    },

  },
  link2: {
    height: "100%",
    width: "100%",
    '&:hover': {
      '& $overlaySecondary': {
        color: theme.palette.text.primary,
        background: `radial-gradient(circle at center , ${alpha(theme.palette.common.white, 0)},${alpha(theme.palette.common.white, 0.2)
          } 80%, ${alpha(theme.palette.common.white, 0.9)} 10%)`,
        // background: `linear-gradient(180deg , ${alpha(theme.palette.common.white, 0.8)},${alpha(theme.palette.common.white, 0.2)
        //   } 10%, ${alpha(theme.palette.common.white, 0.7)} 75%)`,

      },
      // '& $imageMarked': {
      //   opacity: 0,
      // },

    },

  },
  media: {
    height: "100%",
    // overflow: "hidden",
  },
  image: {
    // position: 'relative',
    // borderRadius: "25px",
    // display: "block",
    objectFit: "fill",
    height: "100%",
    width: "100%",
    // width: "100%",
    // height: "auto",
    opacity: 0.5,
  },



}));
const LongOverlayCard = ({ card, primary }) => {
  const classes = useStyles();
  return (

    <Card className={classes.card}  >

      <CardActionArea
        focusRipple
        className={primary ? classes.link : classes.link2}
        component={Link}
        to={card.link}>
        <CardMedia
          className={classes.media}
          classes={{ img: classes.image }}
          children={
            <>{card?.image && <PreviewCompatibleImage
              // className={classes.image}
              imageInfo={card}
            />}</>
          }
        />
        <CardContent
          className={primary ? classes.overlayPrimary : classes.overlaySecondary}>

          <Typography gutterBottom variant="h3" component="h3"
            className={classes.title}>
            {card.title}
          </Typography>

          <Typography gutterBottom variant="h5" component="h6"
            className={classes.text}>
            "{card.quote}"
          </Typography>
          <Typography gutterBottom variant="h4" component="h2" className={classes.text2} >
            Graduated <strong>{card.course}</strong> in <strong>{card.date}</strong>
          </Typography>




        </CardContent>
      </CardActionArea>

    </Card>



  );
};


export default LongOverlayCard;
