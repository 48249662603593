import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import { Link } from "gatsby";
import { useMediaQuery } from "@material-ui/core";
import MUICookieConsent from "./MUICookieConsent";
import { Button } from "@material-ui/core";
import { gradient } from "../components/Gradient";
import { Helmet } from "react-helmet";


const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  mainContainer: {
    flexGrow: 1,
    // [theme.breakpoints.down("sm")]: {
    // marginTop: theme.spacing(4)
    // }
  },
  gradientContainer: {
    position: "relative",
  },
  canvas: {
    width: "100%",
    // height: "100%",
    backgroundColor: theme.palette.primary.main,

    // clipPath: "fill-box",
    clipPath: "ellipse(100% 450px at 30% 0%)",
    // '@media (orientation: landscape)': {

    //   [theme.breakpoints.down("lg")]: {
    //     clipPath: "ellipse(100% 40% at 30% 0%)",
    //   },
    //   [theme.breakpoints.down("md")]: {
    //     clipPath: "ellipse(100% 40% at 30% 0%)",
    //   },
    //   [theme.breakpoints.down("xs")]: {
    //     clipPath: "ellipse(100% 60% at 30% 0%)",
    //   },
    // },
    // '@media (orientation: portrait)': {



    //   [theme.breakpoints.down("sm")]: {
    //     clipPath: "ellipse(100% 40% at 30% 0%)",
    //   },
    //   [theme.breakpoints.down("xs")]: {
    //     clipPath: "ellipse(100% 40% at 30% 0%)",
    //   },
    // },

    "--gradient-color-1": theme.palette.primary.main,
    "--gradient-color-2": "#239E94",
    "--gradient-color-3": "#1A756E",
    position: "absolute",
  }
}));

const TemplateWrapper = ({ children, location }) => {
  const url = location.pathname;
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  const classes = useStyles();
  const injectGA = () => {
    if (typeof window == 'undefined') {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    console.log('first')
    gtag('consent', 'default', {
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'ad_storage': 'denied',
      'analytics_storage': 'denied',
      'wait_for_update': 500,
    });
    gtag('js', new Date());
    gtag('config', 'G-0P579Z5MZR');
  };
  useEffect(() => {
    if (typeof window !== 'undefined' && location?.pathname === "/")
      gradient.initGradient("#gradient-canvas");
  }, [smDown]);
  return (
    <div
      style={{
        maxWidth: "100vw",
        overflowX: "hidden",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Helmet>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=G-0P579Z5MZR`}
        />
        {/* <script >{injectGA()}</script> */}
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('consent', 'default', {
          'ad_user_data': 'denied',
          'ad_personalization': 'denied',
          'ad_storage': 'denied',
          'analytics_storage': 'denied',
          'wait_for_update': 500,
          });
          gtag('js', new Date());

          gtag('config', 'G-0P579Z5MZR');`}
        </script>
      </Helmet>
      <MUICookieConsent
        actions={
          <Button
            component={Link}
            to={`/privacy`}
            color="primary"
            size="small"
          >
            Read More
          </Button>
        }
        cookieName="CAB_Cookies_Consent"
        message="We use cookies to ensure you the best experience. By using our website you agree to our Cookies policy"
      ></MUICookieConsent>

      {location?.pathname === "/"
        // && !smDown
        && <canvas id="gradient-canvas"
          className={classes.canvas}
        ></canvas>}

      <Navbar smDown={smDown} location={location} url={url} />
      <div className={classes.toolbar} />
      <div className={classes.mainContainer}>
        {children}
      </div>

      <Footer location={location} />
    </div>
  );
};

export default TemplateWrapper;
