// @flow
/* eslint-disable no-unused-vars */
import * as React from 'react';
import Cookies from 'js-cookie';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useEffect, useState } from 'react';


/**
 * This component is the MUICookieConsent it pops a Snackbar or a Dialog informing the user about cookie consent.
 */
const MUICookieConsent = ({
    componentType = 'Snackbar',
    cookieName = 'cookie_consent',
    acceptOnScroll = false,
    cookieValue = 'true',
    acceptOnScrollPercentage = 25,
    expires = 365,
    hideOnAccept = true,
    debug = false,
    extraCookiesOptions = undefined,
    snackbarAnchor = { horizontal: 'center', vertical: 'bottom' },
    children = null,
    message = 'I love cookies!',
    title = null,
    acceptButtonLabel = 'Accept',
    actions = null,
}) => {

    const [visible, setVisible] = useState(false);
    useEffect(() => {


        if (Cookies.get(cookieName) === undefined || debug) {
            setVisible(true);
        }

        if (window && acceptOnScroll) {
            window.addEventListener('scroll', handleScroll, { passive: true });
        }
    }, [])








    /**
     * checks whether scroll has exceeded set amount and fire accept if so.
     */
    const handleScroll = () => {
        const { acceptOnScrollPercentage } = this.props;
        if (document && typeof acceptOnScrollPercentage === 'number') {
            const rootNode = document.documentElement || document.body;

            if (rootNode) {
                // (top / (height - height)) * 100
                const percentage =
                    (rootNode.scrollTop /
                        (rootNode.scrollHeight - rootNode.clientHeight)) *
                    100;

                if (percentage > acceptOnScrollPercentage) {
                    handleAccept();
                }
            }
        }
    };

    /**
     * Set a persistent cookie
     */
    const handleAccept = () => {

        // if (onAccept) {
        //     onAccept();
        // }

        if (window) {
            window.removeEventListener('scroll', handleScroll);
        }

        Cookies.set(cookieName, cookieValue, { expires });
        if (window.gtag) {
            window.gtag('consent', 'update', {
                ad_user_data: 'granted',
                ad_personalization: 'granted',
                ad_storage: 'granted',
                analytics_storage: 'granted'

            });
        }
        if (hideOnAccept) {
            setVisible(false);
        }
    };


    const childrenWithProps = React.Children.map(children, child =>
        React.cloneElement(child, { onAccept: handleAccept }),
    );

    switch (componentType) {
        case 'Snackbar':
            return children ? (
                <Snackbar anchorOrigin={snackbarAnchor} open={visible}>
                    {childrenWithProps}
                </Snackbar>
            ) : (
                <Snackbar
                    anchorOrigin={snackbarAnchor}
                    open={visible}
                    message={<span id="message-id">{message}</span>}
                    action={[
                        ...React.Children.toArray(actions),
                        <Button
                            key="accept"
                            color="secondary"
                            size="small"
                            onClick={handleAccept}
                        >
                            {acceptButtonLabel}
                        </Button>,
                    ]}
                />
            );
        case 'Dialog':
            return (
                <Dialog open={visible}>

                    <>
                        {title ? <DialogTitle>{title}</DialogTitle> : null}
                        <DialogContent>
                            <DialogContentText
                                id="alert-dialog-description"
                                component="div"
                            >
                                {message}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            {actions}
                            <Button onClick={handleAccept} color="secondary">
                                {acceptButtonLabel}
                            </Button>
                        </DialogActions>
                    </>

                </Dialog>
            );
    }




}

export default MUICookieConsent;