import React from "react";
import Typography from "@material-ui/core/Typography";
import PreviewCompatibleImage from "../PreviewCompatibleImage";
import ContentJustified from "../ContentJustified";
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Link } from "gatsby";
const useStyles = makeStyles((theme) => ({
  card: {

    // maxWidth: 600,

    [theme.breakpoints.down("sm")]: {
      maxWidth: 500,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: 450,
      height: 300,
    },



  },


  title: {
    fontFamily: "Lovelo",
    // paddingTop: theme.spacing(3),
    // flexGrow: 1,

  },
  text: {
    // paddingTop: theme.spacing(3),
    // fontWeight: 600,
  },


  media: {
    height: 300,
  },
  image: {
    objectFit: "cover",
    height: "100%",
    opacity: 0.5,
  },
}));
const ContentCard = ({ card, primary = true }) => {
  const classes = useStyles();
  return (

    <Card
      className={classes.card}
    >

      <CardActionArea
        focusRipple

        component={Link}
        to={card.link}>


        {card?.image && <CardMedia
          className={classes.media}
          classes={{ img: classes.image }}
          children={<PreviewCompatibleImage
            imageInfo={card}
          />}

        />}
        <CardContent
        >

          <Typography gutterBottom variant="h5" component="h3"
            className={classes.title}>
            {card.title}
          </Typography>

          <Typography gutterBottom variant="body1" component="p"
            className={classes.text}>
            {card.text}
          </Typography>

          {
            card?.html &&
            <ContentJustified content={card.html} />
          }

        </CardContent>

      </CardActionArea>

    </Card>



  );
};


export default ContentCard;
