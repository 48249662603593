import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import { withStyles, useTheme } from "@material-ui/core/styles";
import styles from "./styles";
import "moment/locale/de";
import { Container } from "@material-ui/core";

import SyllabusForm from "../SyllabusForm";
const SyllabusRequest = ({ classes, color, isMobile }) => {
  const [open, setOpen] = React.useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const theme = useTheme();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setIsAgreed(false);
    setOpen(false);
  };

  return (
    <Container
      className={isMobile ? classes.containerMobile : classes.container}
    >
      <Button
        variant="contained"
        style={{
          color: theme.palette.common.white,
          // width: "100%",
          maxWidth: "300px",
          backgroundColor: theme.palette.secondary.main,
          fontFamily: "Lovelo",
        }}
        onClick={handleClickOpen}
      >
        Request our Syllabus
      </Button>

      <SyllabusForm
        open={open}
        handleClose={handleClose}
        isAgreed={isAgreed}
        setIsAgreed={setIsAgreed}
      />
    </Container>
  );
};

export default withStyles(styles)(SyllabusRequest);
