import React from "react";

import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";



const StartCard = ({ card, classes, data, all, jsonLd }) => {



  return (

    <Grid item
      container
      spacing={4}
      justifyContent="center"
      alignItems="center"
      xs={12} md={6}
    >

      {/* <Card className={classes.card}>
                <CardHeader
                  title={card.title}
                  subheader={start.subheader}
                  titleTypographyProps={{
                    fontSize: "2rem",
                    align: "center",
                  }}
                  subheaderTypographyProps={{ align: "center" }}
                  className={classes.cardHeader}
                />
                <CardContent className={classes.cardContent}>
                  <Typography component="h4" variant="h4" color="textPrimary">
                    {start.course
                  </Typography>
                  <HTMLContent
                    className={classes.description}
                    content={start.description.childMarkdownRemark.html}
                  />
                </CardContent>
                <CardActions disableSpacing className={classes.cardActions}>
                  <Link
                    className={classes.link}
                    to={`/${langKey}/application?course=${start.course}&date=${start.date}`}
                  >
                    <Button fullWidth variant="contained">
                     Apply
                    </Button>
                  </Link>
                </CardActions>
              </Card> */}

    </Grid>
  );
};

export default withStyles(styles)(StartCard);

