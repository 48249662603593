const styles = (theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
  },
  columnL: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  columnR: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  icon: {
    margin: theme.spacing(1),
    height: "2em",
    width: "2em",
    [theme.breakpoints.down("sm")]: {
      height: "1em",
      width: "1em",
    },
    color: theme.palette.common.white,
    "&:hover": {
      color: theme.palette.secondary.light,
    },
  },
  links: {
    textDecoration: "none",
    color: theme.palette.common.white,
    "&:hover": {
      color: theme.palette.secondary.light,
    },
  },
  white: {
    color: theme.palette.common.white,
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4),
    },
  },
  waveDiv: {

    position: "relative",
    overflow: "hidden",
    pointerEvents: "none",
  },
  wave: {
    right: 0,
    left: 0,
    bottom: -1,
    width: "100%",
    overflow: "hidden",
    color: theme.palette ? theme.palette.primary.main : "white",
    fill: "currentcolor",
    pointerEvents: "none",
  },
});
export default styles;
