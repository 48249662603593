import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import ContentCard from "./ContentCard";
import OverlayCard from "./OverlayCard";
import OverlayCardButton from "./OverlayCardButton";
import LongOverlayCard from "./LongOverlayCard";
import StartCard from "./StartCard";
import styles from "./styles";
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';



const AvatarChips = ({ chip }) => {
  return (
    <>
      <Chip
        avatar={<Avatar alt="" src={chip.childImageSharp.fixed.src} />}
        // label=""
        variant="outlined"
      />
    </>
  );
}
const CardSwapper = ({ card, primary, variant }) => {
  switch (variant) {
    case "content":
      return <ContentCard card={card} />;
    case "overlayButton":
      return <OverlayCardButton card={card} />;
    case "overlay":
      return <OverlayCard card={card} primary={primary} />;
    case "longOverlay":
      return <LongOverlayCard card={card} primary={primary} />;
    case "start":
      return <StartCard card={card} />;
    default:
      return <ContentCard card={card} />;
  }
}
const Cards = ({ cards, classes, variant, primary }) => {
  return (

    <Grid container
      className={classes.container}
      justifyContent="center"
      spacing={2}

    >

      {cards.map((card, i) => (
        <Grid item sm={12} md={6} lg={4}
          container
          key={i}
          // spacing={1}
          justifyContent="center"
          alignContent="center"
          alignItems="stretch"

        >

          <CardSwapper
            variant={variant}
            card={card}
            primary={primary}

          />
        </Grid>
      ))}

    </Grid>
  );
};

export default withStyles(styles)(Cards);
