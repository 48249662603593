import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import pages from "../../data/pages.json";
import { Link } from "gatsby";
import Logo from "/public/assets/A-logo-primary.svg";
import LogoText from "/public/assets/logo_text2.svg";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
// import GitHubIcon from "@material-ui/icons/GitHub";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import WhatsApp from "@material-ui/icons/WhatsApp";
import FooterSVG from "/public/assets/footerWave.svg";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import styles from "./styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SyllabusForm from "../SyllabusForm";
import BookingModal from "../BookingModal/BookingModal";

const Copyright = ({ classes }) => {
  return (
    <Typography variant="h6" className={classes.white}>
      {"Copyright © "}
      Code Academy Berlin{" "}
      {new Date().getFullYear()}
    </Typography>
  );
};

const Footer = ({ classes, location }) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));


  return (
    <div className={classes.root}>
      <div className={classes.waveDiv}>
        <Hidden smDown>
          <FooterSVG className={classes.wave} />
        </Hidden>
      </div>
      <footer className={classes.footer}>
        <CssBaseline />
        <Container maxWidth="lg">
          <Grid container spacing={2} justifyContent="space-evenly">
            <Grid item xs={6} className={classes.columnL}>
              <SyllabusForm
                color={theme.palette.common.white}
                textMobile={smDown && "Syllabus"}
              />
              <LogoText
                height={smDown ? 12 : 16}
                fill={theme.palette.common.white}
                className={classes.logoText}
              />
              <Logo fill="white" height={smDown ? 80 : 100} />
              <div>
                <a
                  href="https://www.linkedin.com/company/codeacademyberlin"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedInIcon className={classes.icon} />
                </a>
                <a
                  href="https://www.facebook.com/codeacademyberlin"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon className={classes.icon} />
                </a>
                {/* <a
                  href="https://github.com/CodeAcademyBerlin"
                  target="_blank"
                  rel="noreferrer"
                >
                  <GitHubIcon className={classes.icon} />
                </a> */}
                <a aria-label="Chat on WhatsApp"
                  target="_blank"
                  rel="noreferrer"
                  href="https://wa.me/+4915128791278">
                  <WhatsApp className={classes.icon} />
                </a>
                <a
                  href="https://www.instagram.com/codeacademyberlin/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon className={classes.icon} />
                </a>
              </div>
            </Grid>
            <Grid item xs={3} className={classes.columnR}>
              {pages.pages.map((page) => (

                <Link key={page.to}
                  style={{ textDecoration: "none" }}
                  to={page.to}
                >

                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      className={classes.links}
                      gutterBottom
                    >
                      {page.name}
                    </Typography>
                  </Grid>

                </Link>
              ))}
            </Grid>
            <Grid item xs={3} className={classes.columnR}>
              <Link
                style={{ textDecoration: "none" }}
                to={`/advisors`}
              >

                <Typography
                  variant="h6"
                  className={classes.links}
                  gutterBottom
                >
                  Advisors
                </Typography>

              </Link>

              {/* <BookingModal variant="outlined" text={true} /> */}
              <a
                href="mailto:info@codeacademyberlin.com"
                className={classes.links}
              >
                <Typography variant="h6" gutterBottom>
                  Email
                </Typography>
              </a>
              {smDown ? (
                <a href="tel:+4915128791278" className={classes.links}>

                  <Typography
                    variant="h6"
                    className={classes.links}
                    gutterBottom
                  >
                    Call
                  </Typography>

                </a>
              ) : (
                <a href="tel:+4915128791278" className={classes.links}>
                  <Typography variant="h6" gutterBottom>
                    Tel: +49 15128791278
                  </Typography>
                </a>
              )}
              <React.Fragment>
                {smDown ? (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/privacy`}
                  >

                    <Typography
                      variant="h6"
                      className={classes.links}
                      gutterBottom
                    >
                      Privacy
                    </Typography>

                  </Link>
                ) : (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/privacy`}
                  >

                    <Typography
                      variant="h6"
                      className={classes.links}
                      gutterBottom
                    >
                      Privacy Policy
                    </Typography>

                  </Link>
                )}
              </React.Fragment>

              <Link
                style={{ textDecoration: "none" }}
                to={`/impressum`}
              >

                <Typography
                  variant="h6"
                  className={classes.links}
                  gutterBottom
                >
                  Imprint
                </Typography>

              </Link>

            </Grid>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Copyright classes={classes} />
          </Grid>
        </Container>
      </footer>
    </div>
  );
};

Footer.propTypes = {
  langKey: PropTypes.string,
  classes: PropTypes.shape({}),
};

export default withStyles(styles)(Footer);
