
const encode = async (formdata) => {


  // const strapi = formdata.date === "unsure" ?
  const dateObj = new Date(formdata.date);
  const date = isNaN(dateObj.getTime()) ? null : dateObj.toISOString();
  const data = {
    "firstname": `${formdata.firstname}`,
    "lastname": `${formdata.lastname}`,
    "email": `${formdata.email}`,
    "cablifecycle": `${formdata.cablifecycle}`,
    "phone": `${formdata.phone}`,
    "country": `${formdata.country}`,
    "city": `${formdata.berlin ? "Berlin" : ""}`,
    "marketingfunnel": `${formdata.marketingfunnel}`,
    "course": `${formdata.course}`,
    "bildungsgutschein": `${formdata.bildungsgutschein}`,
    "msg": `${formdata.msg}`,
  }
  if (date !== null) data.date = date;
  const strapi = {
    "data": data
  }

  return { strapi };
};


module.exports = { encode };
