import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PreviewCompatibleImage from "../PreviewCompatibleImage";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { alpha } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Link } from "gatsby";

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    margin: theme.spacing(1),
    flex: 1,
    height: "100%",
    // maxWidth: 600,

    [theme.breakpoints.down("sm")]: {
      maxWidth: 500,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: 450,
      height: 300,
    },



  },
  overlayPrimary: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '100%',
    width: "100%",
    color: theme.palette.common.white,
    background: `linear-gradient(180deg , ${alpha(theme.palette.primary.main, 1)},${alpha(theme.palette.primary.main, 0.4)
      } 40%, ${alpha(theme.palette.primary.main, 0.9)}65%)`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",


  },
  overlaySecondary: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '100%',
    width: "100%",
    color: theme.palette.common.white,
    // background: `radial-gradient(circle at center , ${alpha(theme.palette.common.black, 0.2)},${alpha(theme.palette.common.black, 0.3)
    //   } 90%, ${alpha(theme.palette.common.black, 0.9)} 100%)`,
    background: `linear-gradient(180deg , ${alpha(theme.palette.grey[900], 0.6)},${alpha(theme.palette.grey[300], 0.1)
      } 45%, ${alpha(theme.palette.grey[900], 0.7)}65%)`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",

  },
  title: {
    color: theme.palette.common.white,
    fontFamily: "Lovelo",
    paddingTop: theme.spacing(3),
    flexGrow: 1,

  },
  text: {
    paddingTop: theme.spacing(3),
    // fontWeight: 600,
  },
  link: {
    height: "100%",
    width: "100%",
    '&:hover': {
      '& $overlayPrimary': {
        background: `linear-gradient(180deg , ${alpha(theme.palette.common.white, 0.6)},${alpha(theme.palette.common.white, 0.4)
          } 30%, ${alpha(theme.palette.common.white, 0.7)}45%)`,
        color: theme.palette.text.primary

      },
      '& $title': {
        color: theme.palette.text.primary
      },

    },

  },
  link2: {
    height: "100%",
    width: "100%",
    '&:hover': {
      '& $overlaySecondary': {

        background: `radial-gradient(circle at center , ${alpha(theme.palette.common.white, 0)},${alpha(theme.palette.common.white, 0.2)
          } 55%, ${alpha(theme.palette.common.white, 0.7)}85%)`,
        // background: `linear-gradient(180deg , ${alpha(theme.palette.common.white, 0.8)},${alpha(theme.palette.common.white, 0.2)
        //   } 10%, ${alpha(theme.palette.common.white, 0.7)} 75%)`,
        color: theme.palette.text.primary,
      },
      // '& $imageMarked': {
      //   opacity: 0,
      // },

    },

  },
  media: {
    height: "100%",
    // overflow: "hidden",
  },
  image: {
    // position: 'relative',
    // borderRadius: "25px",
    // display: "block",
    objectFit: "fill",
    height: "100%",
    width: "100%",
    // width: "100%",
    // height: "auto",
    opacity: 0.5,
  },
}));
const OverlayCard = ({ card, primary }) => {
  const classes = useStyles();
  return (

    <Card className={classes.card}  >

      {card?.link ?
        <CardActionArea
          focusRipple
          className={primary ? classes.link : classes.link2}
          component={Link}
          to={card.link}>
          {<CardMedia
            className={classes.media}
            classes={{ img: classes.image }}
            children={
              <>{card?.image && <PreviewCompatibleImage
                // className={classes.image}
                imageInfo={card}
              />}</>
            }
          />}
          <CardContent
            className={primary ? classes.overlayPrimary : classes.overlaySecondary}>

            <Typography gutterBottom variant="h3" component="h3"
              className={classes.title}>
              {card.title}
            </Typography>

            <Typography gutterBottom variant="h5" component="h6"
              className={classes.text}>
              {card.text}
            </Typography>



          </CardContent>
        </CardActionArea>
        :
        <>
          <CardMedia
            className={classes.media}
            classes={{ img: classes.image }}
            children={
              <>{card?.image && <PreviewCompatibleImage
                className={classes.image}
                imageInfo={card}
              />}</>
            }
          />
          <CardContent className={primary ? classes.overlayPrimary : classes.overlaySecondary}>
            <Typography gutterBottom variant="h3" component="h3"
              className={classes.title}>
              {card.title}
            </Typography>

            <Typography gutterBottom variant="h5" component="h6"
              className={classes.text}>
              {card.text}
            </Typography>



          </CardContent>
        </>}
    </Card>



  );
};


export default OverlayCard;
