import React from "react";
import PropTypes from "prop-types";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import rehypeReact from "rehype-react"

const renderAst = new rehypeReact().Compiler

export const HTMLAst = ({ content, className }) => (
  <div
  // className={className}
  >
    {renderAst(content)} </div>
);
export const HTMLContent = ({ content, className }) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
);

const ContentJustified = ({ content, className }) => (
  <div className={className}>{content}</div>
);

ContentJustified.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
};

HTMLContent.propTypes = ContentJustified.propTypes;

export default withStyles(styles)(HTMLContent);
