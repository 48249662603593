import React from "react";
import Typography from "@material-ui/core/Typography";
import PreviewCompatibleImage from "../PreviewCompatibleImage";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from '@material-ui/core/ButtonBase';

import { Link } from "gatsby";
const useStyles = makeStyles((theme) => ({
  card: {

    margin: theme.spacing(1),
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",


  },
  image: {
    margin: theme.spacing(1),
    width: '100%',
    flexGrow: 1,
    maxWidth: 600,
    height: 300,

    [theme.breakpoints.down("md")]: {
      height: 250,
      maxWidth: 500,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: 450,
      height: 200,
      // height: 300,
    },

    // position: 'relative',

    // [theme.breakpoints.down('md')]: {
    //   width: '100% !important', // Overrides inline-style
    //   height: 150,
    // },
    // [theme.breakpoints.down('xs')]: {
    //   width: '100% !important', // Overrides inline-style
    //   height: 100,
    // },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',

      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 80%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    fontFamily: "Lovelo",
    whiteSpace: "nowrap",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  text: {

    padding: theme.spacing(2),
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));
const OverlayCardButton = ({ card, primary = true }) => {
  const { title, text, image, link } = card;
  const classes = useStyles();
  return (
    <div className={classes.card} >
      <ButtonBase
        component={Link}
        to={`/en/${link}`}
        focusRipple
        key={title}
        className={classes.image}
        focusVisibleClassName={classes.focusVisible}

      >
        <span
          className={classes.imageSrc}
          style={{

            backgroundImage: `url(${!!image.childImageSharp ? image.childImageSharp.fluid.src : image})`,
          }}
        />
        <span className={classes.imageBackdrop} >
          {/*   <Typography
                                component="p"
                                variant="body1"
                                align="center"
                                gutterBottom
                                className={classes.text}
                            >
                                {text}
                            </Typography>*/}
        </span>
        <span className={classes.imageButton}>
          <Typography
            component="span"
            variant="h4"
            color="inherit"
            className={classes.imageTitle}
          >
            {title}
            <span className={classes.imageMarked} />
          </Typography>
        </span>
      </ButtonBase>

    </div>

  );
};


export default OverlayCardButton;
