const styles = (theme) => ({
  gridContainer: {
    width: "100%",
    height: "100%",
  },
  textRoot: {
    fontSize: "0.9rem",
    padding: theme.spacing(1),
  },
  dialogContainer: {
    minHeight: "35vh",
  },
  button: {
    fontSize: "0.85rem",
    width: "100%",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  buttonMobile: {
    width: "100%",

    backgroundColor: theme.palette.grey[300],
    color: theme.palette.common.black,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  closeIcon: {
    cursor: "pointer",
    alignSelf: "flex-end",
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: theme.spacing(0),
  },
  links: {
    textDecoration: "none",
    color: theme.palette.common.white,
    "&:hover": {
      color: theme.palette.secondary.main,
      cursor: "pointer",
    },
  },
  errorMessage: {
    marginTop: 10,
    color: theme.palette.secondary.main,
    textAlign: "center",
  },
  privacyMessage: {
    textAlign: "justify",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});
export default styles;
