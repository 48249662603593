import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import Menu from "@material-ui/core/Menu";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
// import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import { Link } from "gatsby";
import pages from "../data/pages.json";
import Logo from "/public/assets/A-logo-primary.svg";
import LogoText from "/public/assets/logo_text2.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SyllabusRequest from "./SyllabusRequest";
import BookingModal from "./BookingModal";
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    color: theme.palette.common.black,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      flexGrow: 1,
    },
  },
  logoText: {
    paddingLeft: theme.spacing(1),
    display: "none",
    [theme.breakpoints.up("xs")]: {
      display: "block",
    },
  },
  brand: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  titleShort: {
    flexGrow: 1,
    color: theme.palette.common.black,
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
      flexGrow: 0,
    },
  },
  link: {
    flexGrow: 1,
    display: "flex",
    textDecoration: "none",
    [theme.breakpoints.up("sm")]: {},
  },
  button: {
    color: theme.palette.common.white,
    display: "none",
    letterSpacing: ".2rem",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  subMenu: {
    color: theme.palette.common.black,
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  appBar: {
    display: "flex",
    position: "fixed",
  },
  toolbar: {},
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifySelf: "flex-start",
    },
  },
  underline: {
    paddingTop: 2,
    borderBottom: "2px solid",
    borderBottomColor: theme.palette.secondary.main,
  },
  flexEnd: {
    justifySelf: "flex-end",
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  mobile: {
    fontSize: "1rem",
    width: 300,
    height: 50
  },
  drawer: {
    fontSize: "1rem",
    color: theme.palette.text.primary,
    fontWeight: 400,
    width: 300,
    height: 50
  },
  mobileSubmenu: {
    fontSize: "1rem",
    paddingLeft: "2rem",

    width: 300,
    height: 50
  },
  drawerSubmenu: {
    color: theme.palette.text.primary,
    fontSize: "1rem",
    paddingLeft: "2rem",
    width: 300,
    fontWeight: 400,
    height: 50
  },
  bookButton: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(3),
  },

  applyButton: {
    fontFamily: "Lovelo",
    fontWeight: 100,
    height: "80%",
    alignSelf: "center",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
    },
  },
}));

const NavBar = (props) => {
  const { url, location, smDown } = props;
  const classes = useStyles();
  const theme = useTheme();
  const trigger = useScrollTrigger({
    threshold: 300,
    disableHysteresis: true,
  })
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [coursesAnchorEl, setCoursesAnchorEl] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isCoursesMenuOpen, setIsCoursesMenuOpen] = useState(false);
  const handleMobileMenuClose = () => {
    setIsMobileMenuOpen(false);
    // setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setIsMobileMenuOpen(true);
    // setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleCourseMenuOpen = (event) => {
    setIsCoursesMenuOpen(true);
    setCoursesAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "account-menu-mobile";
  const renderMobileMenu = (
    <SwipeableDrawer
      anchor={"right"}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      onOpen={handleMobileMenuOpen}

    >
      <List    >
        {pages.pages.map((page) => (
          <React.Fragment key={page.name}>
            <ListItem
              className={classes.drawer}
              onClick={handleMobileMenuClose}
              component={Link}
              to={page.to}
            >
              {page.to === url ? (
                <span className={classes.underline}>{page.name}</span>
              ) : (
                page.name
              )}
            </ListItem >{
              page.children && page.children.map((page) => <ListItem
                className={classes.drawerSubmenu}
                onClick={handleMobileMenuClose}
                component={Link}
                to={page.to}
                key={page.name}
              >
                {page.to === url ? (
                  <span className={classes.underline}>{page.name}</span>
                ) : (
                  page.name
                )}
              </ListItem >
              )
            }

          </React.Fragment>
        ))}

      </List>
      <div className={classes.bookButton} >
        <Button
          variant="text"
          onClick={handleMobileMenuClose}
          component={Link}
          color="primary"
          to="/advisors"
        >Talk to our advisors</Button>
        {/* <BookingModal variant="text" /> */}
      </div>
    </SwipeableDrawer >
  );

  const coursesMenu = (
    <Menu
      anchorEl={coursesAnchorEl}
      className={classes.menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      id={"courses-menu"}
      keepMounted
      transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={isCoursesMenuOpen}
      onClose={() => setIsCoursesMenuOpen(false)}
    >
      {pages.pages[0].children.map((page) => (

        <MenuItem
          className={classes.mobile}
          onClick={handleMobileMenuClose}
          component={Link}
          to={page.to}
          key={page.name}
        >
          {page.to === url ? (
            <span className={classes.underline}>{page.name}</span>
          ) : (
            page.name
          )}
        </MenuItem>

      ))}
    </Menu>
  );

  return (
    <div className={classes.root}>
      <AppBar elevation={0} className={classes.appBar} position="sticky" color={(location.pathname === "/" && !trigger) ? "transparent" : "primary"}>
        {/* <MarketingBanner url={url} /> */}

        <Toolbar className={classes.toolbar}>
          <Link to={"/"} className={classes.link}>
            <div className={classes.brand}>
              <Logo height={mdUp ? 35 : 18} fill={theme.palette.common.white} />
              <LogoText
                height={mdUp ? 20 : 12}
                fill={theme.palette.common.white}
                className={classes.logoText}
              />
            </div>
          </Link>
          <div className={classes.sectionDesktop}>
            {coursesMenu}
            {pages.pages.map((page) => (
              <React.Fragment key={page.to}>
                {page.children ?
                  <Button
                    aria-controls="courses-menu"
                    aria-haspopup="true"
                    onClick={handleCourseMenuOpen}
                    className={classes.button}>

                    {url.includes(page.to) ? (
                      <span className={classes.underline}>{page.name}</span>
                    ) : (
                      page.name
                    )}
                  </Button > :

                  <Button
                    key={page.name}
                    id={page.to}
                    component={Link}
                    to={page.to}
                    className={classes.button}
                  >
                    {page.to === url ? (
                      <span className={classes.underline}>{page.name}</span>
                    ) : (
                      page.name
                    )}
                  </Button>}
              </React.Fragment>
            ))}

            <Button
              component={Link}
              to={`/application`}
              variant="outlined"
              className={classes.applyButton}
              color="secondary"
            >
              Apply
            </Button>
          </div>
          <div className={classes.sectionMobile}>
            <Button
              component={Link}
              to={`/application`}
              variant="outlined"
              className={classes.applyButton}
              color="secondary"
            >
              Apply
            </Button>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              style={{ color: theme.palette.common.white }}
            >
              <MenuOpenIcon />
            </IconButton>
          </div>
        </Toolbar>
        {renderMobileMenu}
      </AppBar>
      {/* {mdUp && (
        <SyllabusRequest color={theme.palette.common.white} isMobile={false} />
      )} */}
    </div>
  );
};
export default NavBar;
